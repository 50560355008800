import ThemisInput from "@/components/shared/input"
import ThemisInputAppend from "@/components/shared/input-append"
import ThemisInputAppendOuter from "@/components/shared/input-append-outer"
import ThemisDecision from "@/components/shared/decision"
import { MAX_CHARACTER_LIMIT, LANGUAGE } from "@/constants"

export default {
  name      : "PrivacyPolicy",
  components: {
    ThemisInput,
    ThemisInputAppend,
    ThemisInputAppendOuter,
    ThemisDecision
  },
  data: () => ({
    privacyPolicyDisplayName: null,
    privacyPolicyLink       : null,
    privacyPolicyText       : null,
    privacyPolicySource     : {
      url : false,
      text: false
    },
    isEditingConsent                      : false,
    privacyPolicyDisplayNameCharacterLimit: MAX_CHARACTER_LIMIT.PRIVACY_POLICY_DISPLAY_NAME,
    helpCenterUrl                         : process.env.VUE_APP_HELP_CENTER_URL,
    localConsentValues                    : {
      consent: false
    },
    isPrivacyPolicyEnabled                 : false,
    showAreaCounter                        : false,
    privacyPolicyTextCharacterLimit        : MAX_CHARACTER_LIMIT.PRIVACY_POLICY_TEXT,
    isDisablingConsent                     : false,
    isEditingPrivacyPolicy                 : false,
    displaySaveAndCancelButtons            : false,
    isPrivacyPolicyTextSaved               : false,
    showEditPrivacyPolicyTranslationsDialog: false,
    selectedLanguage                       : LANGUAGE.DUTCH.id,
    localPrivacyPolicyTranslations         : new Object(),
    showEnableConsentDialog                : false,
    showDisableConsentDialog               : false,
    hasDraftPrivacyPolicyData              : false,
    canEnablePrivacyPolicy                 : false,
    showDialogToDisablePrivacyPolicy       : false,
    privacyPolicyTextTranslationStatus     : false,
    privacyPolicyTextTranslationsLimit     : MAX_CHARACTER_LIMIT.PRIVACY_POLICY_TEXT_TRANSLATIONS
  }),
  props: {
    pChannel                                     : Object,
    pIsUpdatingConsent                           : Boolean,
    pIsConsentUpdated                            : Boolean,
    pIsUpdatingDraftPrivacyPolicyText            : Boolean,
    pIsDraftPrivacyPolicyTextUpdated             : Boolean,
    pIsUpdatingPrivacyPolicy                     : Boolean,
    pIsPrivacyPolicyUpdated                      : Boolean,
    pPrivacyPolicyTranslations                   : Object,
    pLanguages                                   : Array,
    pIsUploadingDraftPrivacyPolicyTranslations   : Boolean,
    pIsDisablingPrivacyPolicy                    : Boolean,
    pIsPrivacyPolicyDisabled                     : Boolean,
    pIsDraftPrivacyPolicyTranslationsFileUploaded: Boolean
  },
  computed: {
    disableSaveAndTranslateButton() {
      return this.privacyPolicyText === null
        || this.privacyPolicyText === (this.pChannel.draftPrivacyPolicyText || this.pChannel.privacyPolicyText)
        || this.privacyPolicyText === ""
        || this.privacyPolicyText.length > this.privacyPolicyTextCharacterLimit
    },
    currentActionType() {
      return this.showDialogToDisablePrivacyPolicy ? "DISABLE_PRIVACY_POLICY" : "ENABLE_PRIVACY_POLICY"
    },
    languages() {
      return this.pLanguages.filter(language => language.id !== this.$CONSTANTS.LANGUAGE.CLIENT_DEFAULT.id)
    },
    displayAlertMessage() {
      return this.displaySaveAndCancelButtons && (this.pChannel.privacyPolicyText
        || this.pChannel.draftPrivacyPolicyText)
    },
    disableEditTranslationButton(){
      return this.privacyPolicyTextTranslationStatus
        || !(this.pPrivacyPolicyTranslations && Object.keys(this.pPrivacyPolicyTranslations).length)
    },
    isLanguageSelectionDisabled() {
      return this.localPrivacyPolicyTranslations[this.selectedLanguage]?.length <=
       this.privacyPolicyTextTranslationsLimit
    }
  },
  methods: {
    enableConsent() {
      this.$emit("updateConsent", { consent: true })
      this.showEnableConsentDialog = false
    },
    disableConsent() {
      this.isDisablingConsent = true
      this.$emit("updateConsent", { consent: false })
      this.showDisableConsentDialog = false
    },
    handleSourceChange() {
      if (!this.privacyPolicySource.url) {
        this.privacyPolicySource.text = true
        this.privacyPolicySource.url  = false
      } else {
        this.privacyPolicySource.text = false
        this.privacyPolicySource.url  = true
      }
      if (this.privacyPolicySource.url &&
        this.privacyPolicyText !== null) {
        this.$emit("updatePrivacyPolicy", {
          draftPrivacyPolicyText: null
        })
        this.privacyPolicyText = null
      }
      if (this.privacyPolicySource.text &&
        this.privacyPolicyLink !== null) {
        this.$emit("updatePrivacyPolicy", {
          draftPrivacyPolicyLink: null
        })
        this.privacyPolicyLink = null
      }
    },
    resetPrivacyPolicy() {
      this.isEditingPrivacyPolicy = false
      this.handleCancelDraftPrivacyPolicyText()
      if (!this.privacyPolicyText) {
        this.privacyPolicySource.url  = true
        this.privacyPolicySource.text = false
      }
    },
    handlePrivacyPolicyTextOnFocus(onFocus) {
      onFocus()
      this.showAreaCounter             = true
      this.displaySaveAndCancelButtons = true
    },
    handlePrivacyPolicyDisplayNameOnBlur(onBlur) {
      onBlur()
      const privacyPolicyDisplayName = this.pChannel.draftPrivacyPolicyDisplayName
        ?? this.pChannel.privacyPolicyDisplayName
      if (this.privacyPolicyDisplayName
        && this.privacyPolicyDisplayName !== privacyPolicyDisplayName
        && this.privacyPolicyDisplayName.length <= this.privacyPolicyDisplayNameCharacterLimit
      ) {
        this.$emit("updatePrivacyPolicy", { draftPrivacyPolicyDisplayName: this.privacyPolicyDisplayName })
      } else {
        this.privacyPolicyDisplayName = privacyPolicyDisplayName
      }
    },
    handlePrivacyPolicyLinkOnBlur(onBlur) {
      onBlur()
      const privacyPolicyLink = this.pChannel.draftPrivacyPolicyLink ?? this.pChannel.privacyPolicyLink
      if (this.privacyPolicyLink && this.privacyPolicyLink !== privacyPolicyLink) {
        this.$emit("updatePrivacyPolicy", { draftPrivacyPolicyLink: this.privacyPolicyLink })
      } else {
        this.privacyPolicyLink = privacyPolicyLink
      }
    },
    handleSaveDraftPrivacyPolicyText() {
      if (this.privacyPolicyText) {
        this.$emit("updatePrivacyPolicy", {
          draftPrivacyPolicyText: this.privacyPolicyText
        })
      }
    },
    handleCancelDraftPrivacyPolicyText() {
      this.showAreaCounter             = false
      this.displaySaveAndCancelButtons = false
      this.privacyPolicyLink           = this.pChannel.draftPrivacyPolicyLink
        || this.pChannel.privacyPolicyLink
      this.privacyPolicyText           = this.pChannel.draftPrivacyPolicyText
        || this.pChannel.privacyPolicyText
    },
    handlePrivacyPolicyTextOnBlur(onBlur) {
      onBlur()
      this.showAreaCounter = false
    },
    handleEnablePrivacyPolicy() {
      this.$emit("updatePrivacyPolicy", { publishPrivacyPolicy: true })
      if (this.isPrivacyPolicyEnabled) {
        this.$emit("updatingPrivacyPolicy", true)
      }
    },
    handleUpdatePrivacyPolicyTranslations() {
      if(this.isPrivacyPolicyEnabled) {
        this.$emit("updatePrivacyPolicy", {
          draftPrivacyPolicyText: this.privacyPolicyText
        })
      }
      this.$emit("updatePrivacyPolicyTranslations", this.localPrivacyPolicyTranslations)
    },
    handleEditPrivacyPolicyTranslationsDialogClose() {
      this.localPrivacyPolicyTranslations          = { ...this.pPrivacyPolicyTranslations }
      this.showEditPrivacyPolicyTranslationsDialog = false
      this.selectedLanguage                        = LANGUAGE.DUTCH.id
    }
  },
  watch: {
    pChannel: {
      immediate: true,
      handler  : function(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.localConsentValues.consent = newValue.consent
          this.isPrivacyPolicyEnabled     = newValue.privacyPolicy

          if (!this.privacyPolicyDisplayName) {
            this.privacyPolicyDisplayName = newValue.draftPrivacyPolicyDisplayName ?? newValue.privacyPolicyDisplayName
          }
          if (!this.privacyPolicyLink) {
            this.privacyPolicyLink       = newValue.draftPrivacyPolicyLink ?? newValue.privacyPolicyLink
            this.privacyPolicySource.url = !!this.privacyPolicyLink || this.privacyPolicySource.url
          }
          if (!this.privacyPolicyText) {
            this.privacyPolicyText        = newValue.draftPrivacyPolicyText ?? newValue.privacyPolicyText
            this.privacyPolicySource.text = !!this.privacyPolicyText || this.privacyPolicySource.text
          }
          if (!this.privacyPolicySource.text && !this.privacyPolicySource.url) {
            this.privacyPolicySource.url = true
          }

          this.canEnablePrivacyPolicy = !!this.privacyPolicyDisplayName
            && (!!this.privacyPolicyLink || !!this.privacyPolicyText)
        }
        if (newValue) {
          this.hasDraftPrivacyPolicyData = !!newValue.draftPrivacyPolicyText
            || !!newValue.draftPrivacyPolicyLink
            || !!newValue.draftPrivacyPolicyDisplayName

          this.canEnablePrivacyPolicy = !!this.privacyPolicyDisplayName
            && (!!this.privacyPolicyLink || !!this.privacyPolicyText)
        }
      }
    },
    "pChannel.privacyPolicyTextTranslationStatus": {
      immediate: true,
      handler  : function(newValue) {
        this.privacyPolicyTextTranslationStatus = newValue
      }
    },
    pIsConsentUpdated: {
      handler: function(newValue) {
        this.localConsentValues.consent = this.pChannel.consent
        if (newValue) {
          if (!this.localConsentValues.consent) {
            this.isDisablingConsent = false
          }
        }
      }
    },

    pIsDraftPrivacyPolicyTextUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.isPrivacyPolicyTextSaved    = true
          this.displaySaveAndCancelButtons = false
        }
      }
    },
    pIsPrivacyPolicyUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.hasDraftPrivacyPolicyData = false
          this.isPrivacyPolicyEnabled    = true
          this.isEditingPrivacyPolicy    = false
          this.canEnablePrivacyPolicy    = false
        }
      }
    },
    pIsDisablingPrivacyPolicy: {
      handler: function(newValue) {
        const actionType                                             = this.currentActionType
        this.$DECISIONS[actionType].pActions[1].buttonProps.loading  = newValue
        this.$DECISIONS[actionType].pActions[0].buttonProps.disabled = newValue
      }
    },
    pIsDraftPrivacyPolicyTranslationsFileUploaded: {
      handler: function(newValue) {
        if (newValue) {
          this.hasDraftPrivacyPolicyData = true
        }
      }
    },
    pIsPrivacyPolicyDisabled: {
      handler: function(newValue) {
        if (newValue) {
          this.privacyPolicySource.url          = true
          this.privacyPolicySource.text         = false
          this.showDialogToDisablePrivacyPolicy = false
          this.isEditingPrivacyPolicy           = false
          this.privacyPolicyLink                = null
          this.privacyPolicyText                = null
          this.privacyPolicyDisplayName         = null
          this.isPrivacyPolicyEnabled           = false
          this.canEnablePrivacyPolicy           = false
          this.isEditingConsent                 = false
        }
      }
    },
    pPrivacyPolicyTranslations: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.localPrivacyPolicyTranslations = { ...this.pPrivacyPolicyTranslations }
        }
      }
    }
  }
}