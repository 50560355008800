import ThemisInput from "@/components/shared/input"
import {
  MAX_CHARACTER_LIMIT,
  CHANNEL_TRIAGE_DEFAULT_DESCRIPTIONS,
  ARRAY,
  TRANSLATION_UPLOAD_STATUS,
  TRANSLATION_FILE_UPLOAD_TYPE,
  TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES
} from "@/constants"
import ThemisReadOnly from "@/components/shared/read-only"

export default {
  name      : "Triage",
  components: {
    ThemisInput,
    ThemisReadOnly
  },
  data: () => ({
    isEditingTriage           : false,
    descriptionsCharacterLimit: MAX_CHARACTER_LIMIT.TRIAGE_DESCRIPTIONS,
    localTriageValues         : {
      speakupDescription    : null,
      triageUrl             : null,
      alternativeDescription: null
    },
    isSpeakupDescriptionFocused    : false,
    isAlternativeDescriptionFocused: false,
    isAddTriage                    : false
  }),
  props: {
    pChannel                         : Object,
    pIsTriageUpdated                 : Boolean,
    pIsUpdatingTriage                : Boolean,
    pIsRemovingTriage                : Boolean,
    pIsAddingTriage                  : Boolean,
    pIsDownloadingTriageTranslations : Boolean,
    pIsUploadingTriageTranslationFile: Boolean
  },
  computed: {
    isTriageEnabled() {
      return this.pChannel.speakupDescription && this.pChannel.triageUrl && this.pChannel.alternativeDescription
    },
    readOnlySpeakUpDescription() {
      return this.localTriageValues.speakupDescription
    },
    readOnlyTriageUrl() {
      return this.localTriageValues.triageUrl
    },
    readOnlyAlternativeDescription() {
      return this.localTriageValues.alternativeDescription
    },
    allowedTranslationDocumentType() {
      return Object.values(TRANSLATION_FILE_UPLOAD_TYPE).toString()
    },
    triageTranslationUploadStatus() {
      return this.pChannel?.triageTranslationUploadStatus
    },
    isTriageTranslationUploadInitiated() {
      return this.triageTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.INITIATED
    },
    isTriageTranslationUploadFailed() {
      return this.triageTranslationUploadStatus === TRANSLATION_UPLOAD_STATUS.FAILURE
    }
  },
  methods: {
    submitTriage() {
      if (this.isAddTriage) {
        this.$emit("addTriage", { id: this.pChannel.id, ...this.localTriageValues })
      } else {
        const triageValuesToUpdate = {}
        if (this.pChannel.speakupDescription !== this.localTriageValues.speakupDescription) {
          triageValuesToUpdate.speakupDescription = this.localTriageValues.speakupDescription
        }
        if (this.pChannel.triageUrl !== this.localTriageValues.triageUrl) {
          triageValuesToUpdate.triageUrl = this.localTriageValues.triageUrl
        }
        if (this.pChannel.alternativeDescription !== this.localTriageValues.alternativeDescription) {
          triageValuesToUpdate.alternativeDescription = this.localTriageValues.alternativeDescription
        }

        if (Object.keys(triageValuesToUpdate).length) {
          triageValuesToUpdate.id = this.pChannel.id
          this.$emit("updateTriage", triageValuesToUpdate)
        } else {
          this.isEditingTriage = false
        }
      }
    },
    handleAddTriage() {
      this.isAddTriage     = true
      this.isEditingTriage = true
    },
    handleRemoveTriage() {
      if(this.isAddTriage) {
        this.isEditingTriage = false
      } else {
        const triageValuesToRemove = {
          id                    : this.pChannel.id,
          speakupDescription    : null,
          triageUrl             : null,
          alternativeDescription: null
        }
        this.$emit("removeTriage", triageValuesToRemove)
      }
    },
    handleSpeakupDescriptionInputOnFocusEvent(onFocus) {
      onFocus()
      this.isSpeakupDescriptionFocused = true
    },
    handleSpeakupDescriptionInputOnBlurEvent(onBlur) {
      onBlur()
      this.isSpeakupDescriptionFocused = false
    },
    handleAlternativeDescriptionInputOnFocusEvent(onFocus) {
      onFocus()
      this.isAlternativeDescriptionFocused = true
    },
    handleAlternativeDescriptionInputOnBlurEvent(onBlur) {
      onBlur()
      this.isAlternativeDescriptionFocused = false
    },
    handleDownloadTriageTranslations() {
      this.$emit("downloadTriageTranslations")
    },
    openFileExplorerToSelectDocument() {
      this.$refs.input_upload_triage_translation_document.click()
    },
    onDocumentSelection(event) {
      const file = event.target.files[ARRAY.FIRST]
      if( file.size < TRANSLATION_FILE_SIZE_LIMIT_IN_BYTES) {
        this.$emit("addDocument", file)
      }
    }
  },
  watch: {
    pChannel: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.localTriageValues.speakupDescription     = newValue.speakupDescription
            || CHANNEL_TRIAGE_DEFAULT_DESCRIPTIONS.SPEAKUP
          this.localTriageValues.triageUrl              = newValue.triageUrl
          this.localTriageValues.alternativeDescription = newValue.alternativeDescription
            || CHANNEL_TRIAGE_DEFAULT_DESCRIPTIONS.ALTERNATIVE
        }
      }
    },
    pIsTriageUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.isAddTriage     = false
          this.isEditingTriage = false
        }
      }
    }
  }
}